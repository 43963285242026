.contactus {
    background-color: #222;
  }


.contactus-button {
  width: 10rem;
}

.contactus-icon {
  width: 2.5rem;
}

.contactus-control {
  background-color: #333;
  border-radius: 0;
  outline: none;
  border: 0;
  border-bottom: 1px solid #555;
  color:white;
}

.contactus-control:focus {
  background-color: #333;
  border-radius: 0;
  outline: none;
  border: 0;
  border-bottom: 1px solid #555;
  color:white;
}

.modal-dialog {
  background-color: #666;
  border-radius: 0;
  outline: none;
  border: 0px;
}

.modal-content {
  background-color: #444;
  border-radius: 0;
  outline: none;
  border: 0px;
}

.modal-header {
  background-color: #333;
  border-radius: 0;
  outline: none;
  border: 0px;
}
