.navbar-logo {
  width: 200px;
}

.navbar-main {
  height: 90px;
}

@media screen and (max-width: 768px) {
  .navbar-logo {
    width: 100px;
  }
  
  .navbar-main {
    height: 70px;
  }
}