.MainBanner {
    background-color: #000;
    min-height: 300px;
    overflow: hidden;
    justify-content: center;
    
    /* border:1px solid white;
    height:300px;
    width:100%;
    padding: 0px; */
  }

.MainImage {
  min-height: 300px;
  min-width: 100%;
  object-fit: cover;
  object-position: center;
}

.MainCaption {
  font-size: 2rem;
  margin-top: 5rem;
  margin-bottom: 3rem;
  white-space: pre-line;
}

@media screen and (max-width: 768px) {
  .MainCaption {
    font-size: 1.5rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
}

