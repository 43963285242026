@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$font-family-sans-serif: 'Noto Sans', sans-serif;

.theme-daalive-dark {

    /* redefine theme colors for dark theme */
    $primary: #012345;
    $secondary: #111111;
    $success: #222222;
    $dark: #111111;
    
    $theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "danger": $danger,
        "info": $indigo,
        "dark": $light,
        "light": $dark,
    );

    /* redefine theme color variables */
    @each $color, $value in $theme-colors {
        --#{$variable-prefix}#{$color}: #{$value};
    }

    /* redefine theme color rgb vars (used for bg- colors) */
    $theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
    @each $color, $value in $theme-colors-rgb {
        --#{$variable-prefix}#{$color}-rgb: #{$value};
    }

    $body-color: #eeeeee;
    $body-bg: #111111;
    
    --#{$variable-prefix}body-color: #{$body-color};
    --#{$variable-prefix}body-bg: #{$body-bg};
      
}

@import '~bootstrap/scss/bootstrap.scss'; 
