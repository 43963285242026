.welcomepage-section {
    /* border: 1px solid white; */
}

.anchor {
    display: block;
    position: relative;
    height: 93px;
    width: 0px;
    top: -93px;
    visibility: hidden;
    float: left;
    z-index: 99;
}

@media screen and (max-width: 768px) {
    .anchor {
        display: block;
        position: relative;
        height: 73px;
        width: 0px;
        top: -73px;
        visibility: hidden;
        float: left;
        z-index: 99;
    }
  }